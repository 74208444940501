:root {
    --brand-primary            : #056bb4;
    --brand-primary-lighten-10 : #0688e6;
    --brand-primary-lighten-40 : #89ccfc;
    --brand-primary-darken-5   : #045c9b;
    --brand-primary-darken-10  : #044e82;
    --brand-primary-darken-12  : #034878;
    --brand-primary-opacity-25 : #056bb440;
    --brand-primary-opacity-50 : #056bb480;

    --brand-secondary          : #222222;

    --highlight                : #b6dcf7;
    --highlight-tint           : #ebf6fe;

    --navbar-height            : 64px;
    --navbar-brand-width       : 150px;
    --navbar-brand-height      : 64px;
    --navbar-brand-margin-left : 10px;
    --navbar-brand-logo-size   : auto;
    --navbar-brand-logo        : url('/assets/images/clients/bmw-us/navbar-brand-logo.png');
    --navbar-brand-logo-mobile : url('/assets/images/clients/bmw-us/navbar-brand-logo.png');

    --logo-gap                 : 36px;
    --logo-bg-size             : auto;
    --login-logo-height        : 19px;
    --login-logo-width         : 171px;
    --login-logo               : url('/assets/images/powered_by.png');
    --login-brand-logo         : url('/assets/images/clients/bmw-us/login_logo.png');
    --login-brand-logo-width   : 100px;
    --login-brand-logo-height  : 100px;

    --sidebar-width            : 220px;
    --mobile-sidebar-width     : 220px;

    --sidebar-bg               : #000;
    --sidebar-bg-darken-3      : black;
    --sidebar-bg-darken-5      : black;
    --sidebar-bg-darken-10     : black;
    --sidebar-bg-lighten-5     : #0d0d0d;

    --combined-bar-green       : #18b52d;
    --combined-bar-blue        : #224197;
    --combined-bar-yellow      : #f9bb11;
    --combined-bar-red         : #c51230;
}