  a {
    color: var(--brand-primary);
  }

  .btn-primary,
  .fc-today-button {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn-primary.disabled,
  .disabled.fc-today-button,
  .btn-primary:disabled,
  .fc-today-button:disabled {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn:focus,
  .fc button:focus,
  .btn.focus,
  .fc button.focus {
    box-shadow: 0 0 0 2px var(--brand-primary-opacity-25);
  }

  .btn-primary:focus,
  .fc-today-button:focus,
  .btn-primary.focus,
  .focus.fc-today-button {
    box-shadow: 0 0 0 2px var(--brand-primary-opacity-50);
  }

  .btn-primary:hover,
  .fc-today-button:hover {
    background-color: var(--brand-primary-darken-10);
    border-color: var(--brand-primary-darken-12);
  }

  .btn-outline-primary {
    color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn-outline-primary:hover {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: var(--brand-primary);
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show>.btn-outline-primary.dropdown-toggle {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn-link {
    color: var(--brand-primary);
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: var(--brand-primary);
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: var(--brand-primary);
    background-color: var(--brand-primary);
  }

  .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: var(--brand-primary);
    background-color: var(--brand-primary);
  }

  .custom-range::-webkit-slider-thumb {
    background-color: var(--brand-primary);
  }

  .custom-range::-moz-range-thumb {
    background-color: var(--brand-primary);
  }

  .custom-range::-ms-thumb {
    background-color: var(--brand-primary);
  }

  .nav-pills .nav-link.active,
  .nav-pills .navbar .active.dropdown-toggle,
  .navbar .nav-pills .active.dropdown-toggle,
  .nav-pills .show>.nav-link,
  .nav-pills .navbar .show>.dropdown-toggle,
  .navbar .nav-pills .show>.dropdown-toggle {
    background-color: var(--brand-primary);
  }

  .page-link,
  .pagination-datatables li a,
  .pagination li a {
    color: var(--brand-primary);
  }

  .page-item.active .page-link,
  .page-item.active .pagination-datatables li a,
  .pagination-datatables li .page-item.active a,
  .page-item.active .pagination li a,
  .pagination li .page-item.active a,
  .pagination-datatables li.active .page-link,
  .pagination-datatables li.active a,
  .pagination li.active .page-link,
  .pagination li.active a {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .badge-primary {
    color: #fff;
    /* background-color: var(--brand-primary); */
  }

  .border-primary {
    border-color: var(--brand-primary) !important;
  }

  .text-primary {
    color: var(--brand-primary) !important;
  }

  /* Global Event Styles
  --------------------------------------------------------------------------------------------------*/
  .fc-event {
    border: 1px solid var(--brand-primary);
    /* default BORDER color */
    background-color: var(--brand-primary);
  }

  .irs-bar {
    background: var(--brand-primary);
  }

  .irs-bar-edge {
    background: var(--brand-primary);
  }

  /**
  * Adjust Select2's choices hover and selected styles to match Bootstrap 3's default dropdown styles.
  */
  .select2-results .select2-highlighted {
    color: white;
    background-color: var(--brand-primary);
  }

  /* Bootstrap theme */
  /* Helper class to show styles when focus */
  .btn-default-focus {
    border-color: var(--brand-primary);
  }

  .select2-container-active .select2-choice,
  .select2-container-multi.select2-container-active .select2-choices {
    border-color: var(--brand-primary) !important;
  }

  .callout-primary {
    border-left-color: var(--brand-primary);
  }

  .callout-primary h4 {
    color: var(--brand-primary);
  }

  .card-header.card-header-primary {
    background: var(--brand-primary);
    border-bottom: 1px solid var(--brand-primary);
  }

  .card-primary .card-header {
    border-color: var(--brand-primary-darken-5);
  }

  .card-primary .card-header,
  .card-primary .card-footer {
    background-color: var(--brand-primary-darken-5);
  }

  .card-primary {
    background-color: var(--brand-primary);
  }

  .card-accent-primary {
    border-top-color: var(--brand-primary);
  }

  .app-footer .text-primary {
    color: var(--brand-primary) !important;
  }

  #loading-bar .bar {
    background: var(--brand-primary);
  }

  .pace .pace-progress {
    background: var(--brand-primary);
  }

  .modal-primary .modal-content {
    border-color: var(--brand-primary);
  }

  .modal-primary .modal-header {
    background-color: var(--brand-primary);
  }

  .flyout-menu .flyout-menu-title i {
    color: var(--brand-primary);
  }

  .switch-primary>.switch-input:checked~.switch-label {
    background: var(--brand-primary) !important;
    border-color: var(--brand-primary-darken-10);
  }

  .switch-primary-outline>.switch-input:checked~.switch-label {
    border-color: var(--brand-primary);
  }

  .switch-primary-outline>.switch-input:checked~.switch-label::after {
    color: var(--brand-primary);
  }

  .switch-primary-outline>.switch-input:checked~.switch-handle {
    border-color: var(--brand-primary-darken-10);
  }

  .switch-primary-outline-alt>.switch-input:checked~.switch-label {
    border-color: var(--brand-primary);
  }

  .switch-primary-outline-alt>.switch-input:checked~.switch-label::after {
    color: var(--brand-primary);
  }

  .switch-primary-outline-alt>.switch-input:checked~.switch-handle {
    background: var(--brand-primary) !important;
    border-color: var(--brand-primary);
  }

  .app-body .router-container .toggle-secondary-nav {
    background-color: var(--brand-primary);
  }

  *[dir=rtl] .callout-primary {
    border-right-color: var(--brand-primary);
  }

  .breadcrumb h2 {
    color: var(--brand-primary);
  }

  .btn-active {
    color: #fff;
    background: var(--brand-primary);
  }

  .btn-primary,
  .fc-today-button {
    color: #fff;
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn-primary.disabled,
  .disabled.fc-today-button,
  .btn-primary:disabled,
  .fc-today-button:disabled {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn-outline-primary {
    color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn-outline-primary:hover {
    color: #fff;
    /* var(--brand-primary-contrast) */
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn-outline-primary:focus,
  .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem var(--brand-primary-opacity-50);
  }

  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: var(--brand-primary);
  }

  .btn-primary:active,
  .fc-today-button:active,
  .btn-primary.active,
  .active.fc-today-button,
  .show>.btn-primary.dropdown-toggle,
  .show>.dropdown-toggle.fc-today-button {
    background-color: var(--brand-primary-darken-10);
    border-color: var(--brand-primary-darken-12);
  }


  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    /* var(--brand-primary-contrast) */
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem var(--brand-primary-opacity-50);
  }

  .btn-link {
    font-weight: normal;
    color: var(--brand-primary);
  }

  .trafficSummary label.btn-primary.active,
  .trafficSummary label.active.fc-today-button,
  .trafficDetail label.btn-primary.active,
  .trafficDetail label.active.fc-today-button {
    background-color: var(--brand-primary);
  }

  .card-primary {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }

  .card-outline-primary {
    border-color: var(--brand-primary);
  }

  .card-accent-primary {
    border-top-color: var(--brand-primary);
  }

  .bg-primary-600 {
    background-color: var(--brand-primary) !important;
  }

  .text-primary-600 {
    color: var(--brand-primary) !important;
  }

  .border-primary-600 {
    border-color: var(--brand-primary) !important;
  }
